<template>
  <div class="Register">
    <confirm ref="confirm"></confirm>

    <v-container>
      <v-row no-gutters>
        <v-col xs10 sm10 md12>
          <v-card-text v-text="PageTitle" class="subtitle-1 grey--text pa-0">
          </v-card-text>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog persistent v-model="progressBar" width="100px">
      <v-card-text style="height: 100px">
        <v-progress-circular :size="70" :width="7" color="black" indeterminate></v-progress-circular>
      </v-card-text>
    </v-dialog>

    <v-dialog scrollable persistent v-model="searchcoadialog" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Search for a Certificate of Analysis using Batch No.</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 200px">
          <v-row no-gutters>
            <v-col cols="12" sm="12" md="12">
              <p class="text-center">
                The Batch No. is visible on your product label.
              </p>

              <v-text-field v-model="CoaBatchNo" ref="CoaBatchNo" label="Batch No" @focus="$event.target.select()" filled :rules="Min3Char" dense
                maxlength="20" counter></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn text @click="gotoback"> Close </v-btn>
          <v-spacer></v-spacer>

          <v-btn color="primary" text @click="searchcoanow"> Submit </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>


<script>
// @ is an alias to /src
import axios from "axios";
import Confirm from "@/components/Confirm";
export default {
  components: { Confirm },
  data() {
    return {
      CoaBatchNo: "",
      searchcoadialog: true,
      PageTitle: "Certificate of Analysis (COA)",
      progressBar: false,
      Min3Char: [
        (v) => (v && v.length > 2) || "A minimum of 3 characters is required.",
      ],
    };
  },

  created() {
    //this.loadresetpwd();
     setTimeout(() => {
        this.$refs.CoaBatchNo.$refs.input.focus();
      });
  },

  methods: {
    async searchcoanow() {
      this.progressBar = true;
      var DataString = "https://api.welgemoed.io/v2/coa/coaBatchPdf/" + this.CoaBatchNo;
      var Data64 = Buffer.from(DataString).toString('base64');
      var ResponseStatus = 0;
      await axios
        .get(DataString)
        .then((response) => {
          ResponseStatus = response.status;
        })
        .catch((error) => {
          ResponseStatus = error.response.status;
        });

      if (ResponseStatus != 200) {
        await this.$refs.confirm.open(
          "Error",
          "COA Batch Number Not Found",
          "",
          "Close",
          { color: "error" }
        );
      } else {
        window.open(location.protocol + '//'+location.hostname+':' + location.port +'/showdoc/' + Data64, '_blank');

      }
      setTimeout(() => {
        this.$refs.CoaBatchNo.$refs.input.focus();
      });
      this.progressBar = false;
    },

    gotoback() {
      this.searchcoadialog = false;
      this.$router.back();
    },
  },
};
</script>

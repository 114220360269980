<template>
  <div class="Shop">
    <confirm ref="confirm"></confirm>
    <Signindialog ref="Signindialog"></Signindialog>

    <v-dialog v-model="startcartpopupdialog" persistent width="75%" max-width="620" :style="{ zIndex: popoptions.zIndex }"
      @keydown.esc="cancel">
      <v-card>
        <v-toolbar dark :color="popoptions.color" dense flat>
          <v-toolbar-title class="white--text">New Cart</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="pa-4 text-h6 text--primary">Online Pricing is available to Signed in users.</v-card-text>

        <v-card-actions>
          <v-btn text color="blue" @click="SignIn()"> Sign In </v-btn>
          <v-btn text color="blue" @click="GoToRegister()"> Register </v-btn>
        </v-card-actions>

        <v-card-text class="pa-4 text text--primary">If you are an existing customer and do not have Sign in credentials,
          please contact
          <a :href="'mailto:online@minema.co.za'">online@minema.co.za</a> to
          activate your profile.</v-card-text>

        <v-card-actions class="pt-0">
          <v-spacer></v-spacer>
          <v-btn color="blue" text @click.native="ContinueAnyway()">Continue anyway</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog persistent v-model="progressBar" width="100px">
      <v-card-text style="height: 100px">
        <v-progress-circular :size="70" :width="7" color="black" indeterminate></v-progress-circular>
      </v-card-text>
    </v-dialog>






    <v-container>

      <v-card-text class="text-h4  pa-0 green--text text--darken-4">{{ PageTitle }}</v-card-text>
      <v-divider color="#1B5E20"></v-divider>
      <v-banner color="#ECEFF1">
        <v-banner-text class="text-body-2 pa-0">MINEMA Chemicals offers the inspiring innovator a full range of laboratory
          chemicals and reagents.
        </v-banner-text>
      </v-banner>


      <v-card-text>
        <p></p>
      </v-card-text>

      <v-row no-gutters>
        <v-col xs="12" sm="12" md="8" offset-md="2">
          <v-text-field v-model="storesearch" label="Search" clearable clear-icon="mdi-close-circle" filled
            ref="refstoresearch" class="pa-0" @keyup.enter="searchstore(0)">
            <v-tooltip bottom slot="append">
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" color="black" dark @click="searchstore(0)">
                  mdi-magnify
                </v-icon>
              </template>
              <span>Search</span>
            </v-tooltip>

            <v-tooltip bottom slot="append-outer">
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" color="black" dark @click="activatefilterdialog()">
                  {{ filtericonname }}
                </v-icon>
              </template>
              <span>Filters</span>
            </v-tooltip>

            <v-tooltip bottom slot="append-outer">
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" color="black" dark @click="clearfilters()">
                  {{ clearfiltericonname }}
                </v-icon>
              </template>
              <span>Clear Filters</span>
            </v-tooltip>
          </v-text-field>
        </v-col>
      </v-row>

      <v-row no-gutters>
        <v-col xs="6" sm="6" md="4" offset-md="2">
          <v-chip class="mr-2" @click="activatesortdialog()">
            <v-icon left> mdi-format-list-bulleted-square </v-icon>
            {{ this.sortitems[this.selectedSortItem].text }}
            <v-icon right>
              {{ this.sortitems[this.selectedSortItem].icon }}</v-icon>
          </v-chip>
        </v-col>
        <v-col xs="1" sm="3" md="2"> </v-col>
        <v-col xs="5" sm="3" md="2" class="text-end">
          <v-chip> {{ this.$store.state.TotalCount }} results found. </v-chip>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog scrollable v-model="filterdialog" max-width="600px" @input="selectnewfilter()">
      <v-card>
        <v-card-title>
          <span class="headline">Search Filters</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 400px">
          <v-treeview selectable v-model="DispCat" item-disabled="locked" :items="DispCats" :open.sync="openlevel1"
            @input="refreshfiltericon()"></v-treeview>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="blue darken-1" text @click="selectnewfilter()">
            Apply
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog scrollable v-model="sortdialog" max-width="300px">
      <v-card>
        <v-card-title>
          <span class="headline">Sorting</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 200px">
          <v-list dense>
            <v-list-item-group color="primary">
              <v-list-item v-for="(sortitem, i) in sortitems" :key="i" @click="selectnewsort(i)">
                <v-list-item-icon>
                  <v-icon v-text="sortitem.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="sortitem.text"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="blue darken-1" text @click="sortdialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog scrollable v-model="showprodpicdialog" max-width="600px" fullscreen>
      <v-card>
        <v-card-title>
          <span class="headline">{{ this.SelectedProduct.Code }}
            <v-btn :ripple="false" icon id="no-background-hover" absolute top right @click="showprodpicdialog = false">
              <v-icon>mdi-close-circle</v-icon>
            </v-btn>
          </span>

          <span class="headline"> {{ this.SelectedProduct.Description }} </span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 600px">
          <v-carousel hide-delimiters="true" show-arrows="false" reverse-transition="fade-transition"
            transition="fade-transition">
            <v-carousel-item v-for="(FileImage, i) in SelectedProduct.FileImages" :key="i"
              :src="BaseURL + 'estore_api/sitelib/prodimages/' + FileImage" contain></v-carousel-item>
          </v-carousel>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog scrollable v-model="showproddetdialog" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">{{ this.SelectedProduct.Code }}</span>

          <v-btn :ripple="false" icon id="no-background-hover" absolute top right @click="showproddetdialog = false">
            <v-icon>mdi-close-circle</v-icon>
          </v-btn>
        </v-card-title>

        <v-divider></v-divider>
        <v-card-text style="height: 800px">
          <v-container grid-list-md>
            <v-row no-gutters v-if="haspics">
              <v-img :src="BaseURL +
                'estore_api/sitelib/prodimages/' +
                this.SelectedProduct.FileImages[0]
                " max-height="100px" contain @click="showprodpicdialog = !showprodpicdialog">
              </v-img>
            </v-row>

            <v-row no-gutters>
              <v-layout row wrap class="mb-3 text-h6 text-center">
                <v-col cols="12" sm="12" md="12">
                  <div>{{ this.SelectedProduct.Description }}</div>
                </v-col>
              </v-layout>
            </v-row>

            <v-row no-gutters v-if="this.SelectedProduct.ProdTypeDes1 != ''">
              <v-layout row wrap class="ml-3 mb-3">
                <v-col cols="4" sm="4" md="4">
                  <div class="caption grey--text">
                    {{ this.SelectedProduct.Desc1 }}:
                  </div>
                </v-col>
                <v-col cols="8" sm="8" md="8">
                  <div>{{ this.SelectedProduct.ProdTypeDes1 }}</div>
                </v-col>
              </v-layout>
            </v-row>

            <v-row no-gutters v-if="this.SelectedProduct.ProdTypeDes2 != ''">
              <v-layout row wrap class="ml-3 mb-3">
                <v-col cols="4" sm="4" md="4">
                  <div class="caption grey--text">
                    {{ this.SelectedProduct.Desc2 }}:
                  </div>
                </v-col>
                <v-col cols="8" sm="8" md="8">
                  <div>{{ this.SelectedProduct.ProdTypeDes2 }}</div>
                </v-col>
              </v-layout>
            </v-row>

            <v-row no-gutters v-if="this.SelectedProduct.ProdTypeDes3 != ''">
              <v-layout row wrap class="ml-3 mb-3">
                <v-col cols="4" sm="4" md="4">
                  <div class="caption grey--text">
                    {{ this.SelectedProduct.Desc3 }}:
                  </div>
                </v-col>
                <v-col cols="8" sm="8" md="8">
                  <div>{{ this.SelectedProduct.ProdTypeDes3 }}</div>
                </v-col>
              </v-layout>
            </v-row>

            <v-row no-gutters v-if="this.SelectedProduct.ProdTypeDes4 != ''">
              <v-layout row wrap class="ml-3 mb-3">
                <v-col cols="4" sm="4" md="4">
                  <div class="caption grey--text">
                    {{ this.SelectedProduct.Desc4 }}:
                  </div>
                </v-col>
                <v-col cols="8" sm="8" md="8">
                  <div>{{ this.SelectedProduct.ProdTypeDes4 }}</div>
                </v-col>
              </v-layout>
            </v-row>

            <v-row no-gutters v-if="this.SelectedProduct.UnitOfMeasure != ''">
              <v-layout row wrap class="ml-3 mb-3">
                <v-col cols="4" sm="4" md="4">
                  <div class="caption grey--text">Units of Measure:</div>
                </v-col>
                <v-col cols="8" sm="8" md="8">
                  <div>{{ this.SelectedProduct.UnitOfMeasure }}</div>
                </v-col>
              </v-layout>
            </v-row>

            <v-row no-gutters>
              <v-layout row wrap class="ml-3 mb-3">
                <v-col cols="4" sm="4" md="4">
                  <div class="caption grey--text" v-if="ShowPriceIncl">
                    Price Incl:
                  </div>
                  <div class="caption grey--text" v-if="!ShowPriceIncl">
                    Price Excl:
                  </div>
                </v-col>
                <v-col cols="8" sm="8" md="8">
                  <div v-if="this.SelectedProduct.PriceIncl == 0">
                    On Request
                  </div>
                  <div v-if="ShowPriceIncl & (this.SelectedProduct.PriceIncl != 0)">
                    {{ DisplayCurrency(this.SelectedProduct.PriceIncl) }}
                  </div>
                  <div v-if="!ShowPriceIncl & (SelectedProduct.Price != 0)">
                    {{ DisplayCurrency(this.SelectedProduct.Price) }}
                  </div>
                </v-col>
              </v-layout>
            </v-row>

            <v-row no-gutters v-if="this.SelectedProduct.Stock != ''">
              <v-layout row wrap class="ml-3 mb-3">
                <v-col cols="4" sm="4" md="4">
                  <div class="caption grey--text">Stock:</div>
                </v-col>
                <v-col cols="8" sm="8" md="8">
                  <div>{{ this.SelectedProduct.Stock }}</div>
                </v-col>
              </v-layout>
            </v-row>

            <v-row no-gutters v-if="this.SelectedProduct.ProdTypeDescurl1 != ''">
              <v-layout row wrap class="ml-3 mb-3">
                <v-col cols="4" sm="4" md="4">
                  <div class="caption grey--text">
                    {{ this.SelectedProduct.Descurl1 }}:
                  </div>
                </v-col>
                <v-col cols="8" sm="8" md="8">
                  <div>
                    <a :href="this.SelectedProduct.ProdTypeDescurl1" target="_blank">Open tab to {{
                      this.SelectedProduct.Descurl1 }}</a>
                  </div>
                </v-col>
              </v-layout>
            </v-row>

            <v-row no-gutters v-if="this.SelectedProduct.ProdTypeDescurl2 != ''">
              <v-layout row wrap class="ml-3 mb-3">
                <v-col cols="4" sm="4" md="4">
                  <div class="caption grey--text">
                    {{ this.SelectedProduct.Descurl2 }}:
                  </div>
                </v-col>
                <v-col cols="8" sm="8" md="8">
                  <div>
                    <a :href="this.SelectedProduct.ProdTypeDescurl2" target="_blank">Open tab to {{
                      this.SelectedProduct.Descurl2 }}</a>
                  </div>
                </v-col>
              </v-layout>
            </v-row>

            <v-divider v-if="this.SelectedProduct.FullDescription != ''"></v-divider>
            <v-row no-gutters v-if="this.SelectedProduct.FullDescription != ''">
              <v-layout class="ml-3 mb-3 pa-1">
                <v-col cols="12" sm="12" md="12" lg="12">
                  <v-card-text v-for="line in this.SelectedProduct.FullDescription" v-bind:key="line.id"
                    class="pa-0 text-body-2">
                    {{ line }}
                  </v-card-text>
                </v-col>
              </v-layout>
            </v-row>

            <v-divider v-if="this.SelectedProduct.Attributes != ''"></v-divider>
            <v-row no-gutters v-if="this.SelectedProduct.Attributes != ''">
              <v-layout class="ml-3 mb-3 pa-1">
                <v-col cols="12" sm="12" md="12" lg="12">
                  <v-card-text v-for="line in this.SelectedProduct.Attributes" v-bind:key="line.id"
                    class="pa-0 text-body-2">
                    {{ line }}
                  </v-card-text>
                </v-col>
              </v-layout>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>

        <div class="my-4 text-center">
          <v-chip color="grey-light" small @click="minuscart(SelectedProduct)">
            <v-icon> mdi-minus </v-icon>
          </v-chip>
          <v-chip small v-bind:color="showcart[SelectedProduct.Code] === 0 ? 'grey-light' : 'info'
            " @click="editItem(SelectedProduct)">
            <v-icon left> mdi-cart </v-icon>
            {{ showcart[SelectedProduct.Code] }}
          </v-chip>
          <v-chip color="grey-light" small @click="pluscart(SelectedProduct)">
            <v-icon> mdi-plus </v-icon>
          </v-chip>
        </div>
      </v-card>
    </v-dialog>

    <v-dialog scrollable v-model="editdialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">Quantity - {{ this.SelectedProduct.Code }}</span>
          <v-btn :ripple="false" icon id="no-background-hover" absolute top right @click="editdialog = false">
            <v-icon>mdi-close-circle</v-icon>
          </v-btn>
        </v-card-title>

        <v-divider></v-divider>
        <v-card-text>
          <v-container grid-list-md>
            <v-row no-gutters>
              <v-col cols="12" sm="12" md="12">
                <div class="my-4 text-center">
                  <v-chip large @click="editProductQty--">
                    <v-icon> mdi-minus </v-icon>
                  </v-chip>

                  <v-chip large>
                    <v-text-field v-model="editProductQty" @focus="$event.target.select()" ref="editProductQty"
                      type="number" min="0" style="width: 100px"></v-text-field>
                  </v-chip>

                  <v-chip large @click="editProductQty++">
                    <v-icon> mdi-plus </v-icon>
                  </v-chip>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="blue darken-1" text @click="saveItem(SelectedProduct)">
            Done
          </v-btn>
          <v-btn color="blue darken-1" text @click="editdialog = false">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-container class="my-1">
      <v-card class="pa-2 ma-5" v-for="Product in this.$store.state.Products" :key="Product.Code">
        <v-row no-gutters style="cursor: pointer">
          <v-layout row wrap>
            <v-col cols="12" sm="3" md="2" @click="showproddet(Product)">
              <v-card flat class="py-0" v-if="ShowPicSearch" outlined color="transparent">
                <v-row no-gutters>
                  <v-img v-if="Product.FileImages" :src="BaseURL +
                    'estore_api/sitelib/prodimages/' +
                    Product.FileImages[0]
                    " max-height="100px" contain>
                  </v-img>
                </v-row>
              </v-card>
            </v-col>

            <v-col cols="12" sm="5" md="2" @click="showproddet(Product)">
              <div class="caption grey--text">Product Code</div>
              <div>{{ Product.Code }}</div>
            </v-col>
            <v-col cols="12" sm="6" md="4" @click="showproddet(Product)">
              <div class="caption grey--text">Description</div>
              <div>{{ Product.Description }}</div>
            </v-col>
            <v-col cols="12" sm="5" md="2" @click="showproddet(Product)">
              <div class="caption grey--text">Category</div>
              <v-card flat class="py-0" outlined color="transparent" v-for="Category in Product.Categories"
                :key="Category">
                <v-row no-gutters tran>
                  <div>{{ Category }}</div>
                </v-row>
              </v-card>
            </v-col>
            <v-col cols="12" sm="4" md="2">
              <div class="caption grey--text" v-if="ShowPriceIncl">
                Price Incl.
              </div>
              <div class="caption grey--text" v-if="!ShowPriceIncl">
                Price Excl.
              </div>
              <div v-if="Product.PriceIncl == 0">On Request</div>
              <div v-if="ShowPriceIncl & (Product.PriceIncl != 0)">
                {{ DisplayCurrency(Product.PriceIncl) }}
              </div>
              <div v-if="!ShowPriceIncl & (Product.Price != 0)">
                {{ DisplayCurrency(Product.Price) }}
              </div>

              <div class="my-4 text-center">
                <v-chip color="grey-light" small @click="minuscart(Product)">
                  <v-icon> mdi-minus </v-icon>
                </v-chip>
                <v-chip small @click="editItem(Product)" v-bind:color="showcart[Product.Code] === 0 ? 'grey-light' : 'info'
                  ">
                  <v-icon left> mdi-cart </v-icon>
                  {{ showcart[Product.Code] }}
                </v-chip>
                <v-chip color="grey-light" small @click="pluscart(Product)">
                  <v-icon> mdi-plus </v-icon>
                </v-chip>
              </div>
            </v-col>
          </v-layout>
        </v-row>
      </v-card>

      <v-row no-gutters>
        <v-col xs="12" sm="12" md="12" class="text-center">
          <v-chip @click="loadmore()" v-if="this.$store.state.Products.length < this.$store.state.TotalCount
            ">
            Showing {{ this.$store.state.Products.length }} of
            {{ this.$store.state.TotalCount }} Load More
          </v-chip>
          <v-chip v-if="this.$store.state.Products.length >=
            this.$store.state.TotalCount && this.$store.state.TotalCount > 0
            ">
            Showing {{ this.$store.state.Products.length }} of
            {{ this.$store.state.TotalCount }}</v-chip>
        </v-col>
      </v-row>

      <v-btn v-scroll="onScroll" v-show="fab" fab dark fixed bottom right color="primary" @click="toTop">
        <v-icon>keyboard_arrow_up</v-icon>
      </v-btn>
    </v-container>
  </div>
</template>


<script>
// @ is an alias to /src
import Confirm from "@/components/Confirm";
import Signindialog from "@/components/SignIn";
export default {
  components: { Confirm, Signindialog },

  data() {
    return {
      StoreName: "",
      PageTitle: "Online Store",
      progressBar: false,
      haspics: false,
      filterdialog: false,
      filtericonname: "mdi-numeric-0-box-outline",
      clearfiltericonname: "",
      sortdialog: false,
      editdialog: false,
      selectedSortItem: 0,
      ExpandFirstLevel: false,
      ShowPriceIncl: true,
      ShowPicSearch: true,
      storesearch: "",
      DispCats: [],
      DispCat: [],
      ODispCat: [],
      openlevel1: [0],
      filters: [],
      SelectedProduct: [],
      editProductQty: 0,
      showprodpicdialog: false,
      showproddetdialog: false,
      fab: false,

      startcartpopupdialog: false,
      popoptions: {
        color: "error",
        width: 290,
        zIndex: 200,
      },

      BaseURL: "",
      sortitems: [
        { text: "Product Code", icon: "mdi-sort-alphabetical-ascending" },
        {
          text: "Description",
          icon: "mdi-sort-alphabetical-ascending",
        },
      ],
    };
  },

  watch: {
    "$store.state.issignedin"() {
      this.startcartpopupdialog = false;
      this.searchstore(0);
    },

    "$store.state.Storedetails"() {
      this.loadStoredetails();
    },

    "$store.state.DispCats"() {
      this.loadDispCats();
    },
    editProductQty() {
      if (this.editProductQty < 0) {
        this.editProductQty = 0;
      }
    },
  },

  created() {
    this.StoreName = location.hostname;
    (this.BaseURL = location.protocol + "//" + location.hostname + "/"),
      this.refreshform();
  },

  computed: {
    showcart() {
      return this.$store.state.Products.reduce((acc, Product) => {
        var objIdx = this.$store.state.Acart.findIndex(
          (x) => x.Code === Product.Code
        );

        if (objIdx != -1) {
          acc[Product.Code] = this.$store.state.Acart[objIdx].Qty;
        } else {
          acc[Product.Code] = 0;
        }

        return acc;
      }, {});
    },
  },

  methods: {
    async refreshform() {
      this.filterdialog = false;
      this.filters = [];
      this.DispCats = [];
      this.progressBar = true;
      this.loadDispCats();
      this.loadStoredetails();

      this.progressBar = false;
      this.refreshfiltericon();
    },

    // 0 = New Search , xx = add records
    async searchstore(lastrec) {
      this.saveShopfields();
      this.progressBar = true;
      this.myJsonResp = await this.PostData(
        this.StoreName,
        "SearchStore",
        JSON.stringify({
          Usertoken: sessionStorage.getItem(this.StoreName + "Usertoken"),
          Dispcat: this.DispCat,
          storesearch: this.storesearch,
          selectedSortItem: this.sortitems[this.selectedSortItem].text,
          lastrec: lastrec,
        })
      );

      if (this.myJsonResp != "") {
        this.data = JSON.parse(this.myJsonResp);
        this.$store.dispatch("updateTotalCount", this.data.TotalCount);
        if (lastrec == 0) {
          this.$store.dispatch("updateProducts", this.data.Products);
        } else {
          this.$store.dispatch("addProducts", this.data.Products);
        }
      }
      this.progressBar = false;
    },

    async loadmore() {
      this.searchstore(this.$store.state.Products.length);
    },

    async activatefilterdialog() {
      this.$refs.refstoresearch.focus();
      this.filterdialog = true;
      this.ODispCat = this.DispCat.slice();
    },

    selectnewfilter() {
      this.filterdialog = false;
      if (JSON.stringify(this.DispCat) != JSON.stringify(this.ODispCat)) {
        this.searchstore(0);
      }
    },

    refreshfiltericon() {
      this.filters = Array.from(this.DispCat);

      switch (this.filters.length) {
        case 0:
          this.filtericonname = "mdi-numeric-0-box-outline";
          this.clearfiltericonname = "";
          if (this.ExpandFirstLevel == true) {
            this.openlevel1 = [1];
          } else {
            this.openlevel1 = [0];
          }
          break;
        case 1:
          this.filtericonname = "mdi-numeric-1-box-outline";
          this.clearfiltericonname = " mdi-close-box-outline";
          break;
        case 2:
          this.filtericonname = "mdi-numeric-2-box-outline";
          this.clearfiltericonname = " mdi-close-box-outline";
          break;
        case 3:
          this.filtericonname = "mdi-numeric-3-box-outline";
          this.clearfiltericonname = " mdi-close-box-outline";
          break;
        case 4:
          this.filtericonname = "mdi-numeric-4-box-outline";
          this.clearfiltericonname = " mdi-close-box-outline";
          break;
        case 5:
          this.filtericonname = "mdi-numeric-5-box-outline";
          this.clearfiltericonname = " mdi-close-box-outline";
          break;
        case 6:
          this.filtericonname = "mdi-numeric-6-box-outline";
          this.clearfiltericonname = " mdi-close-box-outline";
          break;
        case 7:
          this.filtericonname = "mdi-numeric-7-box-outline";
          this.clearfiltericonname = " mdi-close-box-outline";
          break;
        case 8:
          this.filtericonname = "mdi-numeric-8-box-outline";
          this.clearfiltericonname = " mdi-close-box-outline";
          break;
        case 9:
          this.filtericonname = "mdi-numeric-9-box-outline";
          this.clearfiltericonname = " mdi-close-box-outline";
          break;
        default:
          this.filtericonname = "mdi-numeric-9-plus-box-outline";
          this.clearfiltericonname = " mdi-close-box-outline";
      }
    },

    clearfilters() {
      this.ODispCat = this.DispCat.slice();
      this.DispCat = [];
      if (JSON.stringify(this.DispCat) != JSON.stringify(this.ODispCat)) {
        this.searchstore(0);
      }
    },

    async activatesortdialog() {
      this.$refs.refstoresearch.focus();
      this.sortdialog = true;
    },

    async selectnewsort(newselection) {
      this.sortdialog = false;
      if (newselection != this.selectedSortItem) {
        this.selectedSortItem = newselection;
        this.searchstore(0);
      }
      this.selectedSortItem = newselection;
    },

    showprodpic(pid) {
      this.SelectedProduct = pid;
      this.showprodpicdialog = true;
    },

    showproddet(pid) {
      this.SelectedProduct = pid;
      if (this.SelectedProduct.FileImages.length == 0) {
        this.haspics = false;
      } else {
        this.haspics = true;
      }
      if (this.showprodpicdialog == false) {
        this.showproddetdialog = true;
      }
      document.activeElement.blur();
    },

    loadStoredetails() {
      if (this.$store.state.Storedetails != null) {
        this.ExpandFirstLevel = this.$store.state.Storedetails.ExpandFirstLevel;
        this.ShowPicSearch = this.$store.state.Storedetails.ShowPicSearch;
        this.ShowPriceIncl = this.$store.state.Storedetails.ShowPriceIncl;
        this.refreshfiltericon();
      }
      this.storesearch = this.$store.state.Shopfields.storesearch;
      this.DispCat = this.$store.state.Shopfields.DispCat;
      this.selectedSortItem = this.$store.state.Shopfields.selectedSortItem;
      if (
        this.$store.state.Shopfields.issignedin !== this.$store.state.issignedin
      ) {
        this.searchstore(0);
      } //signed in change while out the page
    },

    saveShopfields() {
      this.$store.dispatch(
        "updateShopfields",
        JSON.parse(
          JSON.stringify({
            storesearch: this.storesearch,
            selectedSortItem: this.selectedSortItem,
            issignedin: this.$store.state.issignedin,
            DispCat: this.DispCat,
          })
        )
      );
    },

    loadDispCats() {
      if (this.$store.state.DispCats != null) {
        this.DispCats = this.$store.state.DispCats;
      }
    },

    pluscart(pid) {
      this.SelectedProduct = pid;
      if (this.$store.state.Acart.length == 0 && this.$store.state.issignedin == false) {
        this.startcartpopupdialog = true;
      }

      if (this.SelectedProduct.Code != "") {
        //if (ShowPriceIncl) {} else {};

        this.$store.dispatch("plusAcart", {
          Product: this.SelectedProduct,
          Qty: 1,
        });
        document.activeElement.blur();
      }
    },

    minuscart(pid) {
      this.SelectedProduct = pid;
      if (this.SelectedProduct.Code != "") {
        //if (ShowPriceIncl) {} else {};

        this.$store.dispatch("minusAcart", {
          Product: this.SelectedProduct,
          Qty: 1,
        });
        document.activeElement.blur();
      }
    },

    saveItem(item) {

      if (this.$store.state.Acart.length == 0 && this.$store.state.issignedin == false) {
        this.startcartpopupdialog = true;
      }

      if (item.Code != "") {
        //if (ShowPriceIncl) {} else {};

        this.$store.dispatch("changeAcart", {
          Product: item,
          Qty: this.editProductQty,
        });
        this.editdialog = false;
        document.activeElement.blur();
      }
    },

    editItem(pid) {
      this.SelectedProduct = pid;
      this.editProductQty = this.getcartqty(pid);
      this.editdialog = true;

      setTimeout(() => {
        this.$refs.editProductQty.focus();
      });
    },

    getcartqty(cartcode) {
      var objIdx = this.$store.state.Acart.findIndex(
        (x) => x.Code === cartcode.Code
      );

      if (objIdx != -1) {
        return this.$store.state.Acart[objIdx].Qty;
      } else {
        return 0;
      }
    },

    GoToRegister() {
      // Clear Cart & go to Register
      this.$store.dispatch("updateAcart", []);
      this.$router.push('/Register/Register');
    },

    ContinueAnyway() {
      this.startcartpopupdialog = false;
    },

    SignIn() {
      this.$refs.Signindialog.open();
    },

    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 50;
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
  },
};
</script>

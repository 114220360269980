import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export const store = new Vuex.Store({
    state: {
        issignedin: null,
        Storedetails: null,
        DispCats: null,
        Userdetails: null,
        Products: [],
        TotalCount: 0,
        Acart: [],
        Shopfields: JSON.parse(JSON.stringify({storesearch: '', selectedSortItem:0, issignedin:null, DispCat:[]}))
    },

    mutations: {
        updateissignedin(state, param) {
            state.issignedin = param
        },

        updateStoredetails(state, param) {
            state.Storedetails = param
        },

        updateDispCats(state, param) {
            state.DispCats = param
        },

        updateUserdetails(state, param) {
            state.Userdetails = param
        },

        updateProducts(state, param) {
            state.Products = param
        }, 

        addProducts(state, param) {
            state.Products = state.Products.concat(param);
        }, 

        updateTotalCount(state, param) {
            state.TotalCount = param
        }, 

        updateAcart(state, param) {
            state.Acart = param
        },

        plusAcart(state, param) {
            // Search if Exists add qty otherwise add to array
            var objIdx = state.Acart.findIndex(
                (x) => x.Code === param.Product.Code
            );
            if (objIdx == -1) {
                state.Acart.push({
                    Code: param.Product.Code,
                    Description: param.Product.Description,
                    UnitOfMeasure: param.Product.UnitOfMeasure,
                    Price: param.Product.Price,
                    PriceIncl: param.Product.PriceIncl,
                    Qty: param.Qty,
                });
            } else {
                var myobj = JSON.parse(
                    JSON.stringify({
                        Code: param.Product.Code,
                        Description: param.Product.Description,
                        UnitOfMeasure: param.Product.UnitOfMeasure,
                        Price: param.Product.Price,
                        PriceIncl: param.Product.PriceIncl,
                        Qty: state.Acart[objIdx].Qty + param.Qty,
                    })
                );
                state.Acart.splice(objIdx, 1, myobj);
            }
        },

        
        minusAcart(state, param) {
            // Search if Exists add qty otherwise add to array
            var objIdx = state.Acart.findIndex(
                (x) => x.Code === param.Product.Code
            );
            if (objIdx !== -1) {
                if (state.Acart[objIdx].Qty > param.Qty) {
                    var myobj = JSON.parse(
                        JSON.stringify({
                            Code: param.Product.Code,
                            Description: param.Product.Description,
                            UnitOfMeasure: param.Product.UnitOfMeasure,
                            Price: param.Product.Price,
                            PriceIncl: param.Product.PriceIncl,
                            Qty: state.Acart[objIdx].Qty - param.Qty,
                        })
                    );
                    state.Acart.splice(objIdx, 1, myobj)
                } else {

                    state.Acart.splice(objIdx, 1)
                }
            }
        },

        changeAcart(state, param) {
            // Search if Exists add qty otherwise add to array
            var objIdx = state.Acart.findIndex(
                (x) => x.Code === param.Product.Code
            );
            if (param.Qty > 0) {
                if (objIdx !== -1) {
                    var myobj = JSON.parse(
                        JSON.stringify({
                            Code: param.Product.Code,
                            Description: param.Product.Description,
                            UnitOfMeasure: param.Product.UnitOfMeasure,
                            Price: param.Product.Price,
                            PriceIncl: param.Product.PriceIncl,
                            Qty: param.Qty,
                        })
                    );
                    state.Acart.splice(objIdx, 1, myobj)
                } else {
                    state.Acart.push({
                        Code: param.Product.Code,
                        Description: param.Product.Description,
                        UnitOfMeasure: param.Product.UnitOfMeasure,
                        Price: param.Product.Price,
                        PriceIncl: param.Product.PriceIncl,
                        Qty: param.Qty,
                    });
                }
            } else {state.Acart.splice(objIdx, 1)}
        },



        updateShopfields(state, param) {
            state.Shopfields = param
        },
    },

    actions: {
        updateissignedin({ commit }, param) {
            commit('updateissignedin', param)
        },

        updateStoredetails({ commit }, param) {
            commit('updateStoredetails', param)
        },

        updateDispCats({ commit }, param) {
            commit('updateDispCats', param)
        },

        updateUserdetails({ commit }, param) {
            commit('updateUserdetails', param)
        },

        updateProducts({ commit }, param) {
            commit('updateProducts', param)
        },

        addProducts({ commit }, param) {
            commit('addProducts', param)
        },

        updateTotalCount({ commit }, param) {
            commit('updateTotalCount', param)
        },

        updateAcart({ commit }, param) {
            commit('updateAcart', param)
        },

        plusAcart({ commit }, param) {
            commit('plusAcart', param);
        },

        minusAcart({ commit }, param) {
            commit('minusAcart', param);
        },

        changeAcart({ commit }, param) {
            commit('changeAcart', param);
        },

        updateShopfields({ commit }, param) {
            commit('updateShopfields', param)
        },
    },

    getters: {},

    modules: {}

})
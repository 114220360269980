import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

import Shop from '../views/Shop.vue'
import MyCart from '../views/MyCart.vue'
import Register from '../views/Register.vue'
import Activate from '../views/Activate.vue'
import ResetPassword from '../views/ResetPassword.vue'
import ShowDoc from '../views/ShowDoc.vue'

import SdsSpecs from '../views/SdsSpecs.vue'
import Coa from '../views/Coa.vue'
import Documents from '../views/Documents.vue'
import About from '../views/About.vue'
import Contactus from '../views/Contactus.vue'
import Specials from '../views/Specials.vue'
import pdf from '../views/pdf.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/Home/',
    name: 'Home',
    component: Home
  },
  {
    path: '/Shop/',
    name: 'Shop',
    component: Shop
  },
  {
    path: '/MyCart/',
    name: 'MyCart',
    component: MyCart
  },
  {
    path: '/Register/:RegType',
    name: 'Register',
    component: Register
  },
  {
    path: '/Activate/:ActivationKey',
    name: 'Activate',
    component: Activate
  },
  {
    path: '/ResetPassword/:ActivationKey',
    name: 'ResetPassword',
    component: ResetPassword
  },
  {
    path: '/ShowDoc/:bloburl',
    name: 'ShowDoc',
    component: ShowDoc
  },

  {
    path: '/SdsSpecs/',
    name: 'SdsSpecs',
    component: SdsSpecs
  },
  {
    path: '/Coa/',
    name: 'Coa',
    component: Coa
  },
  {
    path: '/Documents/',
    name: 'Documents',
    component: Documents
  },
  {
    path: '/About/',
    name: 'About',
    component: About
  },
  {
    path: '/Contactus/',
    name: 'Contactus',
    component: Contactus
  },
  {
    path: '/Specials/',
    name: 'Specials',
    component: Specials
  },
  {
    path: '/pdf/',
    name: 'pdf',
    component: pdf
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

<template>
  <div class="Register">
    <confirm ref="confirm"></confirm>

    <v-container>
      <v-row no-gutters>
        <v-col xs10 sm10 md12>
          <v-card-text v-text="PageTitle" class="subtitle-1 grey--text pa-0">
          </v-card-text>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog persistent v-model="progressBar" width="100px">
      <v-card-text style="height: 100px">
        <v-progress-circular
          :size="70"
          :width="7"
          color="black"
          indeterminate
        ></v-progress-circular>
      </v-card-text>
    </v-dialog>

  </div>
</template>


<script>
// @ is an alias to /src
import Confirm from "@/components/Confirm";
export default {
  components: { Confirm },
  data() {
    return {
      StoreName: "",
      ActivationKey: "",
      PageTitle: "Activate",
      progressBar: false,
    };
  },

  created() {
    this.StoreName = location.hostname;
    this.ActivationKey = this.$route.params.ActivationKey;
    this.activating();
  },

  methods: {
    async activating() {
      this.progressBar = true;

      this.myJsonResp = await this.PostData(
        this.StoreName,
        "Activating",
        JSON.stringify({ActivationKey:this.ActivationKey})
      );

      if (this.myJsonResp != "") {
        this.data = JSON.parse(this.myJsonResp);
        if (this.data.isvalid == 0) {
          await this.$refs.confirm.open(
            "Error",
            this.data.returnmessage,
            "",
            "Close",
            { color: "error" }
          );
          this.$router.push("/Register/");
          
        } else {
          await this.$refs.confirm.open(
            "Success",
            this.data.returnmessage,
            "",
            "Close",
            { color: "success" }
          );
          this.$router.push("/Shop/");
        }
      }
      this.progressBar = false;
      }
    },

    gotoshop() {
      this.registerdialog = false;
      this.$router.push("/Shop/");
    },

    
};
</script>

import axios from "axios"
import Vue from 'vue'

Vue.mixin({
    

    methods: {
        capitalizeFirstLetter(str) {
            return str.charAt(0).toUpperCase() + str.slice(1) + btoa(location.protocol);
        },

        DisplayCurrency (num) {
            
            if (this.$store.state.Storedetails.Curpos == 'LEFT') {
                return this.$store.state.Storedetails.Currency + " " + parseFloat(num).toFixed(2)
            } else {
                return  parseFloat(num).toFixed(2) + " " + this.$store.state.Storedetails.Currency 
            }

        },

        async PostData(poststorename, postcmd, jsondata) {
            // POST request using axios with error handling

            var Baseurl = location.protocol + "//" + location.hostname + "/estore_API/estore_API.aspx?action=WEBAPI";
            //var Baseurl =  "http://localhost/eStore_API/estore_API.aspx?action=WEBAPI";
            var Data64 = Buffer.from(jsondata).toString('base64') ;

            var DataHash = this.$CryptoJS.SHA256(Data64);
            var Postdata = '{"Command":"' + postcmd + '","Data": "' + Data64 + '","Signature" : "' + DataHash  + '"}';
            var RespJson = '';
            var RespJsonData = '';

            await axios
                 .post(Baseurl, Postdata, {
                headers: {
                    StoreName: poststorename
                }})
                .then(
                    (response) => {
                        RespJson = JSON.parse(JSON.stringify(response.data));
                        //RespJsonData = atob(RespJson.Data);
                        RespJsonData = Buffer.from(RespJson.Data, 'base64');
                        this.Signindialog = false;
                    }
                )
                .catch((error) => {
                    RespJsonData = '';
                    RespJson = JSON.parse(JSON.stringify({ Status: 0, Message: error.request.statusText + " (" + error.request.status + ")", Data: "" }));

                });


            switch (RespJson.Status) {
                case 0:
                    await this.$refs.confirm.open(
                        "Error",
                        RespJson.Message,
                        "",
                        "Close",
                        { color: "error" }
                    );
                    break;
                default:
            }
            
            return (RespJsonData)
        },


        async PostDataNoErr(poststorename, postcmd, jsondata) {
            // POST request using axios with error handling

            var Baseurl = location.protocol + "//" + location.hostname + "/estore_API/estore_API.aspx?action=WEBAPI";
            //var Baseurl =  "http://localhost/eStore_API/estore_API.aspx?action=WEBAPI";

            var Data64 = Buffer.from(jsondata).toString('base64') ;
            var DataHash = this.$CryptoJS.SHA256(Data64);
            var Postdata = '{"Command":"' + postcmd + '","Data": "' + Data64 + '","Signature" : "' + DataHash  + '"}';
            var RespJson = '';
            var RespJsonData = '';


            await axios
                 .post(Baseurl, Postdata, {
                headers: {
                    StoreName: poststorename
                }})
                .then(
                    (response) => {
                        RespJson = JSON.parse(JSON.stringify(response.data));
                        
                        RespJsonData = Buffer.from(RespJson.Data, 'base64');
                        this.Signindialog = false;
                    }
                )
                .catch((error) => {
                    RespJsonData = '';
                    RespJson = JSON.parse(JSON.stringify({ Status: 0, Message: error.request.statusText + " (" + error.request.status + ")", Data: "" }));

                });


            
            return (RespJsonData)
        },

        async GeturlBlob(geturl) {
            // GET request using axios with error handling specify the response type 

            var RespJsonData = '';

            await axios
                 .get(geturl, {responseType: 'arraybuffer',
                })
                .then(
                    (response) => {
                        RespJsonData = Buffer.from(response.data, 'base64'); //Response.data is a blob arraybuffer that we encode in base64
                        this.Signindialog = false;
                    }
                )
                .catch((error) => {
                    RespJsonData = error.request.statusText + '';
                });
            
            return (RespJsonData)
        },

        

    }
})








<template>
    <div class="Documents">
        <v-dialog persistent v-model="progressBar" width="100px">
            <v-card-text style="height: 100px">
                <v-progress-circular :size="70" :width="7" color="black" indeterminate></v-progress-circular>
            </v-card-text>
        </v-dialog>

        <v-container class="PageTitle">
            <v-card-text class="text-h4  pa-0 green--text text--darken-4 ">About MINEMA Chemicals</v-card-text>
            <v-divider color="#1B5E20"></v-divider>
            <v-banner color="#ECEFF1">
                <v-banner-text class="text-body-2 pa-0">MINEMA Chemicals is a premier South African owned and managed
                    producer and supplier of laboratory reagents and chemicals, supplemented by sales and distribution of
                    general laboratory wares.
                </v-banner-text>
            </v-banner>


            <v-card-text class="text-h5 pt-10">
                Where We Began
            </v-card-text>

            <v-card-text class="text-subtitle-1 pt-1">
                Back in 1994 (a momentous moment for South Africans) we had our atomic moment. Born out of a need to supply
                laboratory reagents and chemicals to the smaller user within Southern Africa, we began trading in June from
                a modest site in Johannesburg’s western suburbs. Today, our operations hub is located in Laser Park on the
                western extremity of Johannesburg, but now with head office in Western Cape’s winelands district and a
                network of distributions outlets throughout south and southern Africa.
            </v-card-text>

            <v-card-text class="text-h5 pt-10">
                Our Footprint
            </v-card-text>

            <v-card-text class="text-subtitle-1 pt-1">
                Our first steps start at the AECI chemical facility in Modderfontein, where production, warehousing,
                despatch and quality control takes place. This is essentially where the magic happens. We also work closely
                with the Modderfontein Laboratory, who provides invaluable service to our operation. We keep the wheels
                turning by ensuring our customers’ orders are effectively distributed to their desired destination. Since
                inception our footprint has extended beyond the confines of greater Johannesburg and Pretoria Metropoles. We
                now have distributors in all provinces of South Africa and we are branching further afield into Southern
                Africa, with strong alliances in Botswana Mozambique, Malawi and Zambia.
            </v-card-text>

            <v-card-text class="text-h5 pt-10">
                Geared Towards The Future
            </v-card-text>

            <v-card-text class="text-subtitle-1 pt-1">
                Our road forward is paved by our ability to adapt the ever-changing nature of the industry. We are where we
                are today because we made a conscious decision to venture into local production and expand our product
                range. Today our range is for the most part dominated by our branded reagents, chemicals and indicators. We
                do also offer a complete range of supporting consumable From here laboratory products. Feel free to click
                here and browse through our displayed product range. As a tech savvy consumer in the evolving technological
                world that we live, you may have already checked out our online store. Online shopping makes life that much
                easier, right? We are proud of what we have achieved to create a platform where our customers can simply
                browse, quote and browse their desired products, in their own time and convenience.
            </v-card-text>

            <v-card-text class="text-h5 pt-10">
                We Love That We Are South African
            </v-card-text>

            <v-card-text class="text-subtitle-1 pt-1">
                We will never forget our humble beginnings and hold true to our South African roots. We love the fact that
                we are a South African company and we want to supply our fellow South African family. The fact that we
                prefer to keep business in and around South Africa comes with many advantages. Not only does it allow a more
                focused approach to customer relations, we can also be far more flexible with our customer’s needs. We want
                to take care of our customers, form a connection with them and be part of their innovative journey.
            </v-card-text>
        </v-container>



        <v-container class="LibraryTitle">
            <v-card-text class="text-h4  pa-0 green--text text--darken-4">{{ PageTitle }}</v-card-text>
            <v-divider color="#1B5E20"></v-divider>
            <v-banner color="#ECEFF1">
                <v-banner-text class="text-body-2 pa-0">{{ PageDesc }}
                </v-banner-text>
            </v-banner>
        </v-container>

        <v-container class="LibraryTable">

            <v-row>

                <v-col cols="12" xs="12" sm="6" md="4" lg="3" xl="2" v-for="Afile in this.Afiles" :key="Afile.fname">
                    <v-card class="fill-height" @click="openhyperlink(Afile.fline1)">
                        <v-card-text class="pl-2 pr-2 text-h5 green--text text--darken-4 text-center font-italic">{{
                            Afile.fshortdesc }}</v-card-text>

                        <v-row justify="center">
                            <v-img :src=Afile.furl height="250" contain="false">
                            </v-img>
                        </v-row>

                        <v-card-text class="pl-2 pr-2 text-h6 green--text text--darken-4 text-center font-italic">{{
                            Afile.flongdesc }}</v-card-text>
                        <div class="text-center pb-5">
                            <v-btn>
                                <v-card-text>E-mail</v-card-text>
                                <v-icon>mdi-email</v-icon>
                            </v-btn>
                        </div>
                    </v-card>
                </v-col>

            </v-row>



        </v-container>




    </div>
</template>
    
    
<script>
// @ is an alias to /src
import axios from "axios";

export default {
    data() {
        return {
            StoreName: "",
            PageTitle: "",
            PageDesc: "",
            LibBaseURL: "",
            StaffDocLibID: "",
            Afiles: [],
            progressBar: false,
        };
    },

    watch: {
        "$store.state.Storedetails"() {
            this.loadStoredetails();
        },
    },

    created() {
        this.StoreName = location.hostname;
        this.refreshform();
    },

    methods: {
        async refreshform() {
            this.loadStoredetails();
        },

        loadStoredetails() {
            this.progressBar = true;
            if (this.$store.state.Storedetails != null) {
                this.LibBaseURL = this.$store.state.Storedetails.LibBaseURL;
                this.StaffDocLibID = this.$store.state.Storedetails.StaffDocLibID;

                //Get the file Library
                var RespJsonData = "";
                axios
                    .get(this.LibBaseURL + "/GetFilesLib?LibID=" + this.StaffDocLibID)
                    .then((response) => {
                        RespJsonData = response.data; //Response.data is a Json
                        this.PageTitle = RespJsonData.libName;
                        this.PageDesc = RespJsonData.libDescription;
                        this.Afiles = RespJsonData.afiles;
                    })
                    .catch((error) => {
                        RespJsonData = error.request.statusText + "";
                        alert("Library Load error:" + error.request.statusText);
                    });
                this.progressBar = false;
                return RespJsonData;
            }
        },

        openhyperlink(selectedemail) {
            location.href='mailto:'+selectedemail;
        },


    },
};
</script>
    
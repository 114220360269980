<template>
  <div>
  <confirm ref="confirm"></confirm>


  <v-dialog persistent v-model="progressBar" width="100px">
      <v-card-text style="height: 100px">
        <v-progress-circular :size="70" :width="7" color="black" indeterminate></v-progress-circular>
      </v-card-text>
    </v-dialog>


    <v-dialog v-model="Signindialog" width="500">
      <v-card>
        <v-card-title class="grey lighten-2" primary-title>Sign in</v-card-title>
        <v-card-text>
          <v-form class="px-3">
            <v-text-field label="Email" v-model="cemail" prepend-icon="person" ref="EmailAddress" :rules="emailRules"
              maxlength="100" counter></v-text-field>
            <v-text-field type="password" label="Password" v-model="cpassword" prepend-icon="lock"></v-text-field>
            <v-row no-gutters>
              <v-col xs="6" sm="6" md="6" class="text-center">
                <v-btn class="success mx-0 mt-3" @click="Signing">Sign in</v-btn>
              </v-col>
              <v-col xs="6" sm="6" md="6" class="text-center">
                <v-btn class="secondary mx-0 mt-3" @click="resetpwdrequest">Reset Password</v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Confirm from "@/components/Confirm";
export default {
  components: { Confirm} ,
    data() {
      return {
      Signindialog: false,
      BaseURL: "",
      cemail: "",
      cpassword: "",
      progressBar: false,
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) =>
          /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || "E-mail must be valid",
      ],
      }
    },

    created() {
    this.StoreName = location.hostname; //this.$route.params.StoreName;
    this.BaseURL = location.protocol + "//" + location.hostname + ":" + location.port;
  },

    methods: {
    open() {
      this.Signindialog = true;
    },

    async Signing() {
     // this.Signindialog = false;
      this.progressBar = true;
      this.myJsonResp = await this.PostData(
        this.StoreName,
        "LoadUser",
        JSON.stringify(
          JSON.parse(
            JSON.stringify({
              email: this.cemail,
              password: this.cpassword,
              ACART: this.$store.state.Acart,
            })
          )
        )
      );
      this.progressBar = false;
      if (this.myJsonResp != "") {
        this.data = JSON.parse(this.myJsonResp);
        sessionStorage.setItem(
          this.StoreName + "Usertoken",
          this.data.Usertoken
        );
        this.$store.dispatch("updateUserdetails", this.data.USERDETAILS);
        this.$store.dispatch("updateAcart", this.data.ACART);

        if (this.data.Usertoken != "") {
          this.$store.dispatch("updateissignedin", true);
        } else {

          this.$store.dispatch("updateissignedin", false);
          sessionStorage.removeItem(this.StoreName + "Usertoken");
          this.$store.dispatch("updateUserdetails", null);

          this.$store.dispatch("updateissignedin", false);

          await this.$refs.confirm.open(
            "Error",
            "Invalid email address or password!",
            "",
            "Close",
            { color: "error" }
          );

          this.Signindialog = true;
        }
      } 

    },
    async resetpwdrequest() {
      this.progressBar = true;

      this.myJsonResp = await this.PostData(
        this.StoreName,
        "ResetPwdRequest",
        JSON.stringify({
          EmailAddress: this.cemail,
          BaseURL: this.BaseURL + "/ResetPassword/",
        })
      );

      if (this.myJsonResp != "") {
        this.data = JSON.parse(this.myJsonResp);
        if (this.data.isvalid == 0) {
          await this.$refs.confirm.open(
            "Error",
            this.data.returnmessage,
            "",
            "Close",
            { color: "error" }
          );
        } else {
          await this.$refs.confirm.open(
            "Success",
            this.data.returnmessage,
            "",
            "Close",
            { color: "success" }
          );
        }
      }
      this.progressBar = false;
    },

  }
    

};
</script>
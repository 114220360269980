<template>
  <div class="Documents">
    <v-dialog persistent v-model="progressBar" width="100px">
      <v-card-text style="height: 100px">
        <v-progress-circular
          :size="70"
          :width="7"
          color="black"
          indeterminate
        ></v-progress-circular>
      </v-card-text>
    </v-dialog>

    

    
    <v-container class="PageTitle" >
    
     
      <v-card-text class="text-h4  pa-0 green--text text--darken-4">{{ PageTitle }}</v-card-text>
      <v-divider  color="#1B5E20"></v-divider>
      <v-banner color="#ECEFF1" >
        <v-banner-text class="text-body-2 pa-0" >{{ PageDesc }}
        </v-banner-text>
      </v-banner>
 
    </v-container>




    <v-container class="LibraryTable">

    <v-row>
     
      <v-col cols="12" xs="12" sm="6" md="4" lg="3" xl="2" v-for="Afile in this.Afiles" :key="Afile.fname" >
      <v-card class="fill-height"  @click="openhyperlink(Afile.furl)">
        <v-card-text class="pl-2 pr-2 text-h5 green--text text--darken-4 text-center font-italic">{{ Afile.fshortdesc }}</v-card-text>
        <v-card-text >{{ Afile.flongdesc }}</v-card-text>
       </v-card>
      </v-col>
    
    </v-row>

         

    </v-container>





  </div>
</template>
  
  
  <script>
// @ is an alias to /src
import axios from "axios";

export default {
  data() {
    return {
      StoreName: "",
      PageTitle: "",
      PageDesc: "",
      LibBaseURL: "",
      SharedDocLibID: "",
      Afiles: [],
      progressBar: false,
    };
  },

  watch: {
    "$store.state.Storedetails"() {
      this.loadStoredetails();
    },
  },

  created() {
    this.StoreName = location.hostname;
    this.refreshform();
  },

  methods: {
    async refreshform() {
      this.loadStoredetails();
    },

    loadStoredetails() {
      this.progressBar = true;
      if (this.$store.state.Storedetails != null) {
        this.LibBaseURL = this.$store.state.Storedetails.LibBaseURL;
        this.SharedDocLibID = this.$store.state.Storedetails.SharedDocLibID;

        //Get the file Library
        var RespJsonData = "";
        axios
          .get(this.LibBaseURL + "/GetFilesLib?LibID=" + this.SharedDocLibID)
          .then((response) => {
            RespJsonData = response.data; //Response.data is a Json
            this.PageTitle = RespJsonData.libName;
            this.PageDesc = RespJsonData.libDescription;
            this.Afiles = RespJsonData.afiles;
          })
          .catch((error) => {
            RespJsonData = error.request.statusText + "";
            alert("Library Load error:" + error.request.statusText);
          });
        this.progressBar = false;
        return RespJsonData;
      }
    },

    openhyperlink(selectedurl){
      window.open(selectedurl, '_self');
    },


  },
};
</script>
  
<template>
  <div class="Register">
    <confirm ref="confirm"></confirm>

    <v-container>
      <v-row no-gutters>
        <v-col xs10 sm10 md12>
          <v-card-text v-text="PageTitle" class="subtitle-1 grey--text pa-0">
          </v-card-text>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog persistent v-model="progressBar" width="100px">
      <v-card-text style="height: 100px">
        <v-progress-circular
          :size="70"
          :width="7"
          color="black"
          indeterminate
        ></v-progress-circular>
      </v-card-text>
    </v-dialog>

    <v-dialog
      scrollable
      persistent
      v-model="resetpassworddialog"
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Reset Password</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 400px">
          <v-row no-gutters>
            <v-col cols="12" sm="12" md="12">
              <v-text-field
                v-model="EmailAddress"
                ref="EmailAddress"
                label="Email Address"
                filled
                :rules="emailRules"
                dense
                maxlength="100"
                counter
                disabled
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="5" sm="5" md="5">
              <v-text-field
                v-model="Password"
                ref="Password"
                label="Password"
                filled
                dense
                type="password"
                maxlength="254"
                autocomplete="new-password"
                :rules="Min6Char"
              ></v-text-field>
            </v-col>
            <v-col cols="5" sm="5" md="5" offset="2">
              <v-text-field
                v-model="ConfirmPassword"
                ref="ConfirmPassword"
                label="Confirm Password"
                filled
                dense
                type="password"
                maxlength="254"
                autocomplete="new-password"
                :rules="Min6Char"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="blue darken-1" text @click="resetpwdnow">
            Reset
          </v-btn>
          <v-btn color="blue darken-1" text @click="gotoshop"> Cancel </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>


<script>
// @ is an alias to /src
import Confirm from "@/components/Confirm";
export default {
  components: { Confirm },
  data() {
    return {
      StoreName: "",
      ActivationKey: "",
      EmailAddress: "",
      Password: "",
      ConfirmPassword: "",
      resetpassworddialog: false,
      PageTitle: "Reset Password",
      progressBar: false,
      Min6Char: [
        (v) => (v && v.length > 5) || "A minimum of 6 characters required.",
      ],
    };
  },

  created() {
    this.StoreName = location.hostname;
    this.ActivationKey = this.$route.params.ActivationKey;
    this.loadresetpwd();
  },

  methods: {
    async loadresetpwd() {
      this.progressBar = true;

      this.myJsonResp = await this.PostData(
        this.StoreName,
        "LoadResetPwd",
        JSON.stringify({ ActivationKey: this.ActivationKey })
      );

      if (this.myJsonResp != "") {
        this.data = JSON.parse(this.myJsonResp);
        if (this.data.isvalid == 0) {
          await this.$refs.confirm.open(
            "Error",
            this.data.returnmessage,
            "",
            "Close",
            { color: "error" }
          );
          this.$router.push("/Shop/");
        } else {
          this.EmailAddress = this.data.EmailAddress;
        }
        this.resetpassworddialog = true;
      }
      this.progressBar = false;
    },
  

async resetpwdnow() {
      this.progressBar = true;

      this.myJsonResp = await this.PostData(
        this.StoreName,
        "ResetPwdNow",
        JSON.stringify({ ActivationKey: this.ActivationKey,
        Password: this.Password,
        ConfirmPassword: this.ConfirmPassword, })
      );

      if (this.myJsonResp != "") {
        this.data = JSON.parse(this.myJsonResp);
        if (this.data.isvalid == 0) {
          await this.$refs.confirm.open(
            "Error",
            this.data.returnmessage,
            "",
            "Close",
            { color: "error" }
          );
          this.Password="";
          this.ConfirmPassword="";
        } else {
            await this.$refs.confirm.open(
            "Success",
            this.data.returnmessage,
            "",
            "Close",
            { color: "success" }
          );
            this.resetpassworddialog = false;
            this.$router.push("/Shop/");
        }
      }
      this.progressBar = false;
    },

  gotoshop() {
    this.resetpassworddialog = false;
    this.$router.push("/Shop/");
  },
  },
};
</script>

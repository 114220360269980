<template>
  <div style="height: 100vh;">
    <vue-pdf-app :pdf="pdffile" :config="pdfappconfig" :file-name="pdffilename" />
    <v-dialog persistent v-model="progressBar" width="100px">
      <v-card-text style="height: 100px">
        <v-progress-circular :size="70" :width="7" color="black" indeterminate></v-progress-circular>
      </v-card-text>
    </v-dialog>
  </div>
</template>

<script>

import VuePdfApp from "vue-pdf-app";
// import this to use default icons for buttons
import "vue-pdf-app/dist/icons/main.css";
// @ is an alias to /src
export default {
  components: {
    VuePdfApp
  },
  data() {
    return {
      pdffile: "",
      pdffilename: "",
      progressBar: false,
      pdfappconfig: {
        toolbar: {
          toolbarViewerRight: {
            openFile: false,
            viewBookmark: false,
          }
        }

      },


    };
  },

  created() {
    this.getPdf();
  },

  methods: {

    async getPdf() {
      this.progressBar = true;
      this.urlblob = Buffer.from(this.$route.params.bloburl, 'base64');
      this.myJsonResp = await this.GeturlBlob(this.urlblob);
      this.pdffile = this.myJsonResp;
      this.pdffilename = this.urlblob.toString().substring(this.urlblob.toString().lastIndexOf('/') + 1) + '.pdf';
      this.progressBar = false;
      return;
    },

  }
}
</script>

<template>
  <div style="height: 100vh;">
    <vue-pdf-app pdf='pdffile' :config="pdfappconfig" :file-name='pdffilename' />
      
    <v-dialog persistent v-model="progressBar" width="100px">
      <v-card-text style="height: 100px">
        <v-progress-circular :size="70" :width="7" color="black" indeterminate></v-progress-circular>
      </v-card-text>
    </v-dialog>
  </div>
</template>

<script>

import VuePdfApp from "vue-pdf-app";
// import this to use default icons for buttons
import "vue-pdf-app/dist/icons/main.css";
// @ is an alias to /src
export default {
  components: {
    VuePdfApp
  },
  data() {
    return {
      pdffile: "",
      pdffilename: "",
      progressBar: false,
      pdfappconfig: {
        toolbar: {
          toolbarViewerRight: {
            openFile: false,
            viewBookmark: false,
          }
        }

      },


    };
  },

  created() {
    this.getPdf();
  },

  methods: {

    async getPdf() {

//fetch('http://localhost:80/VAT.pdf')
 // .then(res => res.blob()) // Gets the response and returns it as a blob
//.then(blob => {
    // Here's where you get access to the blob
    // And you can use it for whatever you want
    // Like calling ref().put(blob)
    // Here, I use it to make an image appear on the page
    //let objectURL = URL.createObjectURL(blob);
    
   //    this.pdffile = objectURL;
   //   this.pdffilename = 'VAT.pdf';
//}).else(alert('not'));


     this.progressBar = true;
     this.urlblob = this.$route.params.bloburl;
     this.myJsonResp = await this.GeturlBlob(this.urlblob);
     this.pdffile = this.myJsonResp;
     this.pdffilename = this.urlblob.toString().substring(this.urlblob.toString().lastIndexOf('/') + 1) + '.pdf';
     this.progressBar = false;
     return;
    },

  }
}
</script>

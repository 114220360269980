<template>
  <div class="SdsSpecs">
    <confirm ref="confirm"></confirm>

    <v-container>
      <v-row no-gutters>
        <v-col xs10 sm10 md12>
          <v-card-text v-text="PageTitle" class="subtitle-1 grey--text pa-0">
          </v-card-text>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog persistent v-model="progressBar" width="100px">
      <v-card-text style="height: 100px">
        <v-progress-circular
          :size="70"
          :width="7"
          color="black"
          indeterminate
        ></v-progress-circular>
      </v-card-text>
    </v-dialog>

  </div>
</template>


<script>
// @ is an alias to /src
import Confirm from "@/components/Confirm";
export default {
  components: { Confirm },
  data() {
    return {
      PageTitle: "SDS's & Specifications",
      dialogVisible: false,
      progressBar: false,
    };
  },


  watch: {
    dialogVisible(visible) {
      if (visible) {
        // Here you would put something to happen when dialog opens up
        this.activating();
      } else {
        console.log("Dialog was closed!")
      }
    }
  },

  mounted() {
    this.activating();
  },

  
  methods: {
    async activating() {
      

     
      await this.$refs.confirm.open(
            "Information",
            "SDS's & Specifications are available for selected products from the Online Store",
            "",
            "Continue",
            { color: "blue" }
          );
  
          this.$router.push("/Shop/");
    
      

      }
    },

    gotoshop() {
      this.registerdialog = false;
      this.$router.push("/Shop/");
    },

    
};
</script>

//created() {
 // this.$router.push("/Shop/"); 
//},
<template>
  <div class="Register">
    <confirm ref="confirm"></confirm>

    <v-container>
      <v-row no-gutters>
        <v-col xs10 sm10 md12>
          <v-card-text v-text="PageTitle" class="subtitle-1 grey--text pa-0">
          </v-card-text>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog persistent v-model="progressBar" width="100px">
      <v-card-text style="height: 100px">
        <v-progress-circular
          :size="70"
          :width="7"
          color="black"
          indeterminate
        ></v-progress-circular>
      </v-card-text>
    </v-dialog>

    <v-dialog
      scrollable
      persistent
      v-model="registerdialog"
      max-width="600px"
      v-if="this.Integration == 1"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Registration details</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 600px">
          <v-container grid-list-md>
            <v-row no-gutters>
              <v-col cols="12" sm="12" md="12">
                <v-card-text class="pa-4 text text--primary"
                  >If you are an existing customer and do not have Sign in
                  credentials, please contact
                  <a :href="'mailto:online@minema.co.za'"
                    >online@minema.co.za</a
                  >
                  to activate your profile.</v-card-text
                >

                <v-card-text class="pa-4 text text--primary">
                  The registration details are required by us in order to ensure
                  that we quote & process correctly in terms of Company and Tax
                  data, act responsibly with regards the supply of hazardous
                  material and to personalise your online profile to provide you
                  with your companies targeted pricing.
                </v-card-text>

                <v-card-text class="pa-4 text text--primary">
                  Upon approval, your Sign in instructions will be forwarded to
                  you to obtain pricing.
                </v-card-text>

                <v-card-text class="pa-4 text text--primary">
                  *Required fields</v-card-text
                >
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  v-model="DebtorName"
                  ref="DebtorName"
                  label="Registered Name (or Name and Surname for individual)*"
                  filled
                  dense
                  maxlength="100"
                  counter
                  :rules="Min6Char"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  v-model="TradeName"
                  ref="TradeName"
                  label="Trading Name"
                  filled
                  dense
                  maxlength="100"
                  counter
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  v-model="RegIdNo"
                  ref="RegIdNo"
                  label="Business Reg.No. (or ID Number for individual)*"
                  filled
                  dense
                  maxlength="50"
                  counter
                  :rules="Min6Char"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  v-model="VatNo"
                  ref="VatNo"
                  label="VAT Reg.No."
                  filled
                  dense
                  maxlength="50"
                  counter
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  v-model="BusNat"
                  ref="BusNat"
                  label="Nature of your business*"
                  filled
                  dense
                  maxlength="100"
                  counter
                  :rules="Min6Char"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" sm="12" md="12">
                <v-switch
                  v-model="HaveLab"
                  ref="HaveLab"
                  :label="`Do you have a Laboratory? ${YesNo(HaveLab)}`"
                >
                </v-switch>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" sm="12" md="12">
                <v-switch
                  v-model="MicroTest"
                  ref="MicroTest"
                  :label="`Does your Lab do Microbiological testing? ${YesNo(
                    MicroTest
                  )}`"
                >
                </v-switch>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  v-model="ContactName"
                  ref="ContactName"
                  label="Contact Person (First Name and Last Name)*"
                  filled
                  dense
                  maxlength="100"
                  counter
                  :rules="Min6Char"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="12" sm="8" md="6">
                <v-text-field-simplemask
                  v-model="TelNumber"
                  ref="TelNumber"
                  label="Tel. number"
                  v-bind:properties="{
                    prefix: '+',
                    suffix: '',
                    readonly: false,
                    disabled: false,
                    outlined: false,
                    clearable: true,
                    placeholder: '',
                  }"
                  v-bind:options="{
                    inputMask: '#############',
                    outputMask: '#############',
                    empty: null,
                    applyAfter: false,
                    alphanumeric: false,
                    lowerCase: false,
                  }"
                  dense
                ></v-text-field-simplemask>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="12" sm="8" md="6">
                <v-text-field-simplemask
                  v-model="ContactNumber"
                  ref="ContactNumber"
                  label="Mobile number*"
                  v-bind:properties="{
                    prefix: '+',
                    suffix: '',
                    readonly: false,
                    disabled: false,
                    outlined: false,
                    clearable: true,
                    placeholder: '',
                  }"
                  v-bind:options="{
                    inputMask: '#############',
                    outputMask: '#############',
                    empty: null,
                    applyAfter: false,
                    alphanumeric: false,
                    lowerCase: false,
                  }"
                  dense
                ></v-text-field-simplemask>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  v-model="EmailAddress"
                  ref="EmailAddress"
                  label="Email Address*"
                  filled
                  :rules="emailRules"
                  dense
                  maxlength="100"
                  counter
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="12" sm="12" md="12">
                <v-textarea
                  v-model="PostalAddress"
                  label="Postal address*"
                  filled
                  dense
                  :rules="Min6Char"
                ></v-textarea>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="12" sm="12" md="12">
                <v-textarea
                  v-model="Shiptoaddress"
                  label="Delivery/Physical address (delivery fees may apply)*"
                  filled
                  dense
                  :rules="Min6Char"
                ></v-textarea>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="12" sm="12" md="12">
                <v-card-text class="pa-4 text text--primary">
                  By the completing hereof, the Customer acknowledges their
                  acceptance of MINEMA’s General Terms and Conditions of Trade
                  (available for download under our Official Documents) and
                  confirms that these shall govern all transactions of sale
                  between the parties.
                </v-card-text>

                <v-card-text class="pa-4 text text--primary">
                  In terms of the POPI Act, you confirm that by completing the
                  fields, you accept that MINEMA will process and store the
                  information contained herein.
                </v-card-text>

                <v-card-text class="pa-4 text text--primary">
                  Your privacy is of the utmost importance and shall be treated
                  accordingly and in compliance with all published regulations.
                </v-card-text>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="65" sm="6" md="6">
                <v-text-field
                  v-model="CompletedBy"
                  ref="CompletedBy"
                  label="Completed By*"
                  filled
                  dense
                  maxlength="100"
                  counter
                  :rules="Min6Char"
                ></v-text-field>
              </v-col>
              <v-col cols="5" sm="5" md="5" offset="1">
                <v-text-field
                  v-model="CompletedDate"
                  ref="CompletedDate"
                  label="Completed Date*"
                  type="date"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="blue darken-1" text @click="register" v-if="this.RegType == 'Register'"> Register </v-btn>
          <v-btn color="blue darken-1" text @click="register" v-if="this.RegType == 'Quote'"> Register and Quote </v-btn>
          <v-btn color="blue darken-1" text @click="gotoback"> Cancel </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      scrollable
      persistent
      v-model="registerdialog"
      max-width="600px"
      v-if="this.Integration == 0"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Registration details</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 600px">
          <v-container grid-list-md>
            <v-row no-gutters>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  v-model="DebtorName"
                  ref="DebtorName"
                  label="Company Name"
                  filled
                  dense
                  maxlength="100"
                  counter
                  :rules="Min6Char"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  v-model="ContactName"
                  ref="ContactName"
                  label="Contact Person (First Name and Last Name)"
                  filled
                  dense
                  maxlength="100"
                  counter
                  :rules="Min6Char"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="12" sm="8" md="6">
                <v-text-field-simplemask
                  v-model="ContactNumber"
                  ref="ContactNumber"
                  label="Contact number"
                  v-bind:properties="{
                    prefix: '+',
                    suffix: '',
                    readonly: false,
                    disabled: false,
                    outlined: false,
                    clearable: true,
                    placeholder: '',
                  }"
                  v-bind:options="{
                    inputMask: '#############',
                    outputMask: '#############',
                    empty: null,
                    applyAfter: false,
                    alphanumeric: false,
                    lowerCase: false,
                  }"
                  dense
                ></v-text-field-simplemask>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  v-model="EmailAddress"
                  ref="EmailAddress"
                  label="Email Address"
                  filled
                  :rules="emailRules"
                  dense
                  maxlength="100"
                  counter
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="12" sm="12" md="12">
                <v-textarea
                  v-model="Shiptoaddress"
                  label="Delivery/Physical address"
                  filled
                  dense
                  :rules="Min6Char"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="5" sm="5" md="5">
                <v-text-field
                  v-model="Password"
                  ref="Password"
                  label="Password"
                  filled
                  dense
                  type="password"
                  maxlength="254"
                  autocomplete="new-password"
                  :rules="Min6Char"
                ></v-text-field>
              </v-col>
              <v-col cols="5" sm="5" md="5" offset="2">
                <v-text-field
                  v-model="ConfirmPassword"
                  ref="ConfirmPassword"
                  label="Confirm Password"
                  filled
                  dense
                  type="password"
                  maxlength="254"
                  autocomplete="new-password"
                  :rules="Min6Char"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="blue darken-1" text @click="register"> Register </v-btn>
          <v-btn color="blue darken-1" text @click="gotoback"> Cancel </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
  
  
<script>
// @ is an alias to /src
import Confirm from "@/components/Confirm";
export default {
  components: { Confirm },
  data() {
    return {
      StoreName: "",
      BaseURL: "",
      PageTitle: "Register",
      Integration: 0,
      progressBar: false,
      registerdialog: false,
      RegType : '',

      DebtorName: "",
      TradeName: "",
      RegIdNo: "",
      VatNo: "",
      BusNat: "",
      HaveLab: false,
      MicroTest: false,
      ContactName: "",
      TelNumber: "",
      ContactNumber: "",
      EmailAddress: "",
      PostalAddress: "",
      Shiptoaddress: "",
      CompletedBy: "",
      CompletedDate: new Date().toISOString().substring(0, 10),
      Password: "",
      ConfirmPassword: "",
      Min6Char: [
        (v) => (v && v.length > 5) || "A minimum of 6 characters required.",
      ],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) =>
          /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || "E-mail must be valid",
      ],
    };
  },

  watch: {
    "$store.state.Storedetails"() {
      this.loadregister();
    },
  },

  created() {
    this.StoreName = location.hostname;
    this.BaseURL =
      location.protocol +
      "//" +
      location.hostname +
      ":" +
      location.port +
      "/Activate/";
    this.loadregister();
    this.RegType = this.$route.params.RegType;
  },

  methods: {
    YesNo(BoolVal) {
      if (BoolVal == true) {
        return "Yes";
      } else {
        return "No";
      }
    },

    async loadregister() {
      this.progressBar = true;
      this.loadStoredetails();
      this.registerdialog = true;
      this.progressBar = false;
      setTimeout(() => {
        this.$refs.DebtorName.$refs.input.focus();
      });
    },

    gotoback() {
      this.searchcoadialog = false;
      this.$router.back();
    },

    loadStoredetails() {
      this.Integration = this.$store.state.Storedetails.Integration;
    },


    // 0 = New Search , xx = add records
    async register() {
      this.progressBar = true;
      this.myJsonResp = await this.PostData(
        this.StoreName,
        "SubmitRegister",
        JSON.stringify({
          DebtorName: this.DebtorName,
          TradeName: this.TradeName,
          RegIdNo: this.RegIdNo,
          VatNo: this.VatNo,
          BusNat: this.BusNat,
          HaveLab: this.HaveLab,
          MicroTest: this.MicroTest,
          ContactName: this.ContactName,
          TelNumber: this.TelNumber,
          ContactNumber: this.ContactNumber,
          EmailAddress: this.EmailAddress,
          PostalAddress: this.PostalAddress,
          Shiptoaddress: this.Shiptoaddress,
          CompletedBy: this.CompletedBy,
          CompletedDate: this.CompletedDate,
          Password: this.Password,
          ConfirmPassword: this.ConfirmPassword,
          BaseURL: this.BaseURL,
        })
      );

      if (this.myJsonResp != "") {
        this.data = JSON.parse(this.myJsonResp);
        if (this.data.isvalid == 0) {
          await this.$refs.confirm.open(
            "Error",
            this.data.errormsg,
            "",
            "Close",
            { color: "error" }
          );

          setTimeout(() => {
            this.$refs["" + this.data.errorfield + ""].focus();
          });
        } else {
          await this.$refs.confirm.open(
            "Success",
            this.data.errormsg,
            "",
            "Close",
            { color: "success" }
          );
          this.SubmitCart();
          this.$router.push("/Shop/");
        }
      }
      this.progressBar = false;
    },

    async SubmitCart() {
      if (this.$store.state.Acart.length < 1) {
        return;
      }


      // Post
      this.myJsonResp = await this.PostData(
        this.StoreName,
        "SubmitCart",
        JSON.stringify(
          JSON.parse(
            JSON.stringify({
              Usertoken: sessionStorage.getItem(this.StoreName + "Usertoken"),
              DebtorName: this.DebtorName,
              ContactName: this.ContactName,
              ContactNumber: this.ContactNumber,
              EmailAddress: this.EmailAddress,
              Shiptoaddress: this.Shiptoaddress,
              ACART: this.$store.state.Acart,
            })
          )
        )
      );

      if (this.myJsonResp != "") {
        this.data = JSON.parse(this.myJsonResp);

         // No error Handlig (Consider Post Successful)
          this.$store.dispatch("updateAcart", []);
      }
    },


  },
};
</script>
  